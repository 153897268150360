/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import { FormGroup, Label, Input } from "reactstrap";

function ProjectsView() {
  const navigate = useNavigate();
  const { db } = useFirebase();
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPaid, setShowPaid] = useState(false);
  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.projects, (data) => {
      if (data) {
        const keys = Object.keys(data);
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          });
          return result;
        }, []);
        setItems(items);
      } else {
        setItems([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (selectedItem) {
      navigate(`/admin/projects/${selectedItem.id}`);
    }
  }, [selectedItem]);

  const filteredItems = items
    .filter((item) => {
      const opts = { lower: true, replacement: " " };
      const name = slugify(item.name, opts);
      const number = slugify(item.number, opts);
      const customer = slugify(item.customer, opts);
      if (search) {
        return (
          name.includes(search) ||
          number.includes(search) ||
          customer.includes(search)
        );
      }
      return true;
    })
    .filter((item) => {
      // Exclude 'paid' status when showPaid is false
      if (!showPaid && item.status === "paid") {
        return false;
      }
      return true;
    });

  return (
    <>
      <SimpleHeader
        search={setSearch}
        actions={[
          {
            label: "Add Project",
            handler: () => navigate(`/admin/projects/new`),
          },
        ]}
      />
      <Container className="mt--6" fluid>
        <Row className="align-items-center mb-3">
          <Col xs="12">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={showPaid}
                  onChange={(e) => setShowPaid(e.target.checked)}
                />{" "}
                Show Paid Projects
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <ListGroup flush>
                  <ListGroupItemHeading>
                    <Row>
                      <Col lg="3" md="6" sm="6" xs="10">
                        Name
                      </Col>
                      <Col lg="3" md="5" sm="4" className="d-none d-sm-block">
                        Customer
                      </Col>
                      <Col lg="2" className="d-none d-lg-block">
                        Date
                      </Col>
                      <Col lg="3" className="d-none d-lg-block">
                        Status
                      </Col>
                      <Col lg="1" md="1" sm="2" xs="2"></Col>
                    </Row>
                  </ListGroupItemHeading>
                  {!items.length && (
                    <ListGroupItem>
                      <p className="text-center">
                        {loading ? "Loading..." : "There are no items yet"}
                      </p>
                    </ListGroupItem>
                  )}
                  {filteredItems
                    .sort((a, b) =>
                      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                    )
                    .map((item) => (
                      <ListGroupItem
                        key={item.id}
                        action
                        onClick={() => setSelectedItem(item)}
                      >
                        <Row className="align-items-center">
                          <Col
                            lg="3"
                            md="6"
                            sm="6"
                            xs="10"
                            className="text-bold text-nowrap text-truncate"
                          >
                            {item.name}
                            <br />
                            <small className="text-muted">#{item.number}</small>
                          </Col>
                          <Col
                            lg="3"
                            md="5"
                            sm="4"
                            className="d-none d-sm-block text-nowrap text-truncate"
                          >
                            {item.customer}
                          </Col>
                          <Col
                            lg="2"
                            className="d-none d-lg-block text-nowrap text-truncate"
                          >
                            {item.date}
                          </Col>
                          <Col
                            lg="3"
                            className="d-none d-lg-block text-nowrap text-truncate"
                          >
                            {item.status
                              ?.replace(
                                /(?:^|-)([a-z])/g,
                                (match, p1) => " " + p1.toUpperCase()
                              )
                              .trim()}
                          </Col>
                          <Col
                            lg="1"
                            md="1"
                            sm="2"
                            xs="2"
                            className="d-flex flex-row flex-nowrap"
                          >
                            <i className="ni ni-bold-right" />
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProjectsView;
