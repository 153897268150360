import React from "react";
import { Col, FormGroup, Label, Row, UncontrolledTooltip } from "reactstrap";
import { Field, ErrorMessage, FieldArray, useFormikContext } from "formik";
import { materialProvidersOptions } from "./formData";
import { handleNumberInput } from "utils";

const TradeTaskDetailsForm = ({
  errors,
  projectTrades,
  tooltipText,
  values,
  type,
}) => {
  const tradeStatus = [
    "Briefing",
    "Waiting to Buy Materials",
    "Waiting Sub to Start",
    "In Progress",
    "Completed",
  ];
  const formik = useFormikContext();
  const { setFieldValue, validateField } = formik;
  const handleMaterialProviderChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    const newPercentages = { ...values.materialProviderPercentages };

    // Remove percentages for unselected providers
    Object.keys(newPercentages).forEach((provider) => {
      if (!selectedOptions.includes(provider)) {
        delete newPercentages[provider];
      }
    });

    // If only one provider is selected, clear all percentages
    if (selectedOptions.length === 1) {
      setFieldValue("materialProviderPercentages", {});
      // setFieldValue("materialProviderPercentages", { [selectedOptions[0]]: 100 });
      // validateField("materialProviderPercentages");
    } else {
      setFieldValue("materialProviderPercentages", newPercentages);
    }

    setFieldValue("materialProvider", selectedOptions);

    // If there are only two selected fields and they are blank, remove the error message
    if (
      selectedOptions.length >= 2 &&
      Object.values(newPercentages).every((value) => value === "")
    ) {
      setFieldValue("materialProviderPercentages", {}, false);
      // setFieldError("materialProviderPercentages");
    }
  };

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Project Trades
            <sup className={!!errors?.trade ? "text-danger" : ""}>*</sup>
          </label>
          <Field
            required
            name="trade"
            className={`form-control text-dark ${
              errors?.trade ? "is-invalid" : ""
            }`}
            placeholder="Project Trade"
            type={type || "text"}
            list="projectTrades"
          />
          <datalist id="projectTrades">
            {Object.keys(projectTrades)
              .sort()
              .map((item, key) => (
                <option key={key} value={projectTrades[item]}></option>
              ))}
          </datalist>
          <ErrorMessage name="trade" component="div" className="text-danger" />
        </FormGroup>
        <FormGroup>
          <Label
            className="form-control-label font-weight-light"
            id="materialProviderLabel"
          >
            Material Provider
            <sup className={!!errors?.materialProvider ? "text-danger" : ""}>
              *
            </sup>
          </Label>
          <i
            className="fa fa-info-circle"
            id="materialProviderInfoIcon"
            style={{ marginLeft: "5px", cursor: "pointer" }}
          ></i>
          <UncontrolledTooltip
            placement="right"
            target="materialProviderInfoIcon"
          >
            {tooltipText}
          </UncontrolledTooltip>
          <Field
            as="select"
            required
            name="materialProvider"
            className={`form-control text-dark ${
              errors?.materialProvider ? "is-invalid" : ""
            }`}
            multiple
            onChange={handleMaterialProviderChange}
            type={type || ""}
          >
            {materialProvidersOptions.map((provider, key) => (
              <option key={key} value={provider.value}>
                {provider.label}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="materialProvider"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        {values.materialProvider.length > 1 && (
          <FieldArray
            name="materialProviderPercentages"
            render={(arrayHelpers) => (
              <FormGroup>
                <Label className="form-control-label font-weight-light">
                  Material Provider Percentages
                </Label>
                {values.materialProvider.map((provider, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <Col md="3">
                      <Label className="form-control-label font-weight-light">
                        {
                          materialProvidersOptions.find(
                            (opt) => opt.value === provider
                          ).label
                        }
                      </Label>
                    </Col>
                    <Col md="8" className="d-flex align-items-center">
                      <Field
                        name={`materialProviderPercentages.${provider}`}
                        className={`form-control text-dark ${
                          errors?.materialProviderPercentages
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder={`Percentage for ${
                          materialProvidersOptions.find(
                            (opt) => opt.value === provider
                          ).label
                        }`}
                        type="text"
                        onInput={handleNumberInput}
                        onBlur={() => {
                          validateField("materialProviderPercentages");
                        }}
                      />
                      <span>%</span>
                    </Col>
                  </div>
                ))}
                {errors?.materialProviderPercentages && (
                  <div className="text-danger">
                    {errors?.materialProviderPercentages}
                  </div>
                )}
              </FormGroup>
            )}
          />
        )}
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Estimated Cost
            <sup className={!!errors?.estimatedCost ? "text-danger" : ""}>
              *
            </sup>
          </label>
          <Field
            required
            name="estimatedCost"
            className={`form-control text-dark ${
              errors?.estimatedCost ? "is-invalid" : ""
            }`}
            placeholder="Estimated Cost"
            onInput={handleNumberInput}
            type={type || "text"}
            inputMode="numeric"
          />
          <ErrorMessage
            name="estimatedCost"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Trade Status
            <sup className={!!errors?.trade ? "text-danger" : ""}></sup>
          </label>
          <Field
            name="tradeStatus"
            className={`form-control text-dark ${
              errors?.trade ? "is-invalid" : ""
            }`}
            placeholder="Project Trade"
            type={type || "text"}
            list="tradeStatus"
          />
          <datalist id="tradeStatus">
            {Object.keys(tradeStatus)
              .sort()
              .map((item, key) => (
                <option key={key} value={tradeStatus[item]}></option>
              ))}
          </datalist>
          <ErrorMessage name="trade" component="div" className="text-danger" />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Planned Start Date
            <sup
              className={!!errors?.plannedStartDate ? "text-danger" : ""}
            ></sup>
          </label>
          <Field
            name="plannedStartDate"
            className={`form-control text-dark ${
              errors?.plannedStartDate ? "is-invalid" : ""
            }`}
            placeholder="Planned Start Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="plannedStartDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Planned End Date
            <sup
              className={!!errors?.plannedEndDate ? "text-danger" : ""}
            ></sup>
          </label>
          <Field
            name="plannedEndDate"
            className={`form-control text-dark ${
              errors?.plannedEndDate ? "is-invalid" : ""
            }`}
            placeholder="Planned End Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="plannedEndDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Start Date
            <sup className={!!errors?.startDate ? "text-danger" : ""}>
              {errors.startDate}
            </sup>
          </label>
          <Field
            name="startDate"
            className={`form-control text-dark ${
              errors?.startDate ? "is-invalid" : ""
            }`}
            placeholder="Start Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="startDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            End Date
            <sup className={!!errors?.endDate ? "text-danger" : ""}>
              {errors.endDate}
            </sup>
          </label>
          <Field
            name="endDate"
            className={`form-control text-dark ${
              errors?.endDate ? "is-invalid" : ""
            }`}
            placeholder="End Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="endDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
      </Col>
      {/* <Col md="6">
        {values.trade === "Electrical" ? (
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Electrical Specific Field
            </label>
            <Field
              name="electricalSpecificField"
              className={`form-control text-dark`}
              placeholder="Enter Electrical Specific Detail"
              type={type || "text"}
            />
          </FormGroup>
        ) : values.trade === "Plumbing" ? (
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Plumbing Specific Field
            </label>
            <Field
              name="plumbingSpecificField"
              className={`form-control text-dark`}
              placeholder="Enter Plumbing Specific Detail"
              type={type || "text"}
            />
          </FormGroup>
        ) : (
          ""
        )}
      </Col> */}
    </Row>
  );
};

export default TradeTaskDetailsForm;
